import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox, videoBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					Beer Can Island (Pine Key): Battling Zoning and Erosion – Will It
					Survive? | Tampa Bay Boating Adventures
				</title>
				<meta
					name="description"
					content="Is Beer Can Island done for good? A popular Tampa Bay stopover, this island is facing a showdown with zoning laws and erosion. Explore the island's fight for preservation, fundraising struggles, and the debate between nature preservation and party spot. Will Beer Can Island endure? Read on to find out."
				/>
				<meta name="keywords" content="Beer Can Island, Island, Pine Key" />
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="Boats tied together"
									src="../../images/blog/boats-tied-together-beer-can-island.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>
										Beer Can Island (Pine Key): Battling Zoning and Erosion –
										Will It Survive?
									</h1>
									<p>March 04, 2023</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<p>
											There are many great locations to visit when sailing a
											boat around the Tampa Bay area. One of the most popular
											areas to stop off at is Beer Can Island, which is also
											known as Pine Key. The island is a great place to stop and
											spend time with friends, soaking in the sun and cracking
											open a few beers as the name suggests, and is currently
											free to visit. In recent years, however, there has been a
											fragile relationship between the owners of the island and
											the county’s zoning laws, and issues with natural erosion
											from the water surrounding it. Due to this legislation,
											Beer Can Island has been officially ‘canceled’, yet it is
											still a popular spot and survived despite the county’s
											best efforts. Let’s take a closer look at the issues
											surrounding this party spot:
										</p>
										<div className={pageContentMedia}>
											<div className={videoBox}>
												<iframe
													width="100%"
													height="375"
													src="https://youtube.com/embed/kIAtfT7r-xg?si=tptKM7BUlZSFbRl2"
													title="YouTube video player"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                                                            gyroscope; picture-in-picture;
                                                            web-share"
													allowfullscreen
												></iframe>
											</div>
										</div>
										<h2>Fundraising Issues & Land Loss from Erosion</h2>
										<p>
											In October 2020, a{" "}
											<a
												href="https://www.gofundme.com/f/save-our-shore-pine-key-project-preservation"
												target="_blank"
											>
												GoFundMe Page
											</a>{" "}
											was set up by the newest owners of the island, asking for
											funding and support to save the island from erosion. They
											estimated that the island had lost up to 3 acres of land
											in recent years due to erosion and tides and was looking
											for funding to help rectify this and stop the situation
											from getting worse. The funding looked to raise enough
											money to help install geotubes and breakwaters to help
											preserve this spot. Unfortunately, the campaign itself was
											not very successful and only raised $1445 out of its
											$500,000 goal.
										</p>
										<hr />
										<h2>Zoning Issues with Hillsborough County</h2>
										<p>
											Pine Key Island did not fit well into the existing
											categories of the Hillsborough County zoning laws, so
											applied to create a new category for island recreation –
											perfect for Beer Can Island but this was rejected. Some
											were skeptical about the environmental harm that the
											island’s use would cause and were reluctant to grant the
											requests. As the property did not fit into any zoning
											designation, they were asked to stop all events and
											concerts on the island.
										</p>
										<p>
											March of 2023 was described as the ‘last call’ for Beer
											Can Island after the owners of the island were told that
											the county was treating it as a nature preserve – meaning
											that there could be no plans for commercial outlets or
											large numbers of visitors. The current state of the island
											was criticized, as well as further safety concerns due to
											its proximity to shipping lanes and lack of facilities for
											helicopter landing if there was a medical emergency in the
											area. All of these factors were taken into consideration
											when working out how to zone the area.
										</p>
										<hr />
										<h2>Nature Preserve or Party Island?</h2>
										<p>
											It seems like this year is the last chance to try and
											change the commissioner’s minds about the zoning issues
											that are linked to Beer Can Island, with the owners
											desperately seeking a solution that will let them run a
											commercial enterprise and attract more visitors to the
											island. They are once again, appealing to the commission,
											explaining how the issues with medical access are not an
											issue due to trained first aiders and a defibrillator on
											the island, while the shipping lanes issue can be
											counteracted with the argument that the boat captains are
											fully licensed and experienced.
										</p>
										<p>
											What does the future hold for Beer Can Island? Watch this
											space…
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
